<template>
  <div>
    <div class="not-found">
      <div class="wrapper-content">
        <div class="not-found__content">
          <h1 class="not-found__title">
            Такой страницы<br>
            не найдено
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./not-found.scss";

export default {
  name: "NotFound",
  data() {
    return {
      calculator: 10000,
    };
  },
};
</script>
